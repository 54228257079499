import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this lesson, we'll learn about the components of vectors, as well as some basic vector operations.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`After completing this lesson, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`describe vectors in terms of `}<strong parentName="li">{`magnitude`}</strong>{` and `}<strong parentName="li">{`direction`}</strong></li>
      <li parentName="ul">{`define `}<strong parentName="li">{`scalar`}</strong></li>
      <li parentName="ul">{`add, subtract, multiply, and divide vectors`}</li>
      <li parentName="ul">{`add, subtract, multiply, and divide vectors using a scalar`}</li>
    </ul>
    {
      /* # Prerequisites
      Before you start, ...
      - ??? */
    }
    <h1 {...{
      "id": "vectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#vectors",
        "aria-label": "vectors permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vectors`}</h1>
    <p>{`Vectors can be thought of as `}<em parentName="p">{`n`}</em>{`-dimensional points in space.  `}</p>
    <h3 {...{
      "id": "1d",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#1d",
        "aria-label": "1d permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1D`}</h3>
    <p>{`If we decided to represent words using a single feature (ex. a sentiment score), we could represent them as 1D vectors:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "csv"
    }}><pre parentName="div" {...{
        "className": "language-csv"
      }}><code parentName="pre" {...{
          "className": "language-csv"
        }}><span parentName="code" {...{
            "className": "token value"
          }}>{`x`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`0.5`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`0.75`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`0.9`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/6b4d5804e9195100984b4ea31da1408d/652b0/1d-example.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "11.486486486486488%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAACABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAEF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB3QQH/8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQABBQJ//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAFxAAAwEAAAAAAAAAAAAAAAAAAAERQf/aAAgBAQABPyEio8P/2gAMAwEAAgADAAAAEHgP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QJ//EABsQAAICAwEAAAAAAAAAAAAAAAERALExQWGB/9oACAEBAAE/ENeG5koNkNclQn//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"Vectors with a single dimension.\"",
              "title": "Vectors with a single dimension.",
              "src": "/static/6b4d5804e9195100984b4ea31da1408d/1c72d/1d-example.jpg",
              "srcSet": ["/static/6b4d5804e9195100984b4ea31da1408d/a80bd/1d-example.jpg 148w", "/static/6b4d5804e9195100984b4ea31da1408d/1c91a/1d-example.jpg 295w", "/static/6b4d5804e9195100984b4ea31da1408d/1c72d/1d-example.jpg 590w", "/static/6b4d5804e9195100984b4ea31da1408d/a8a14/1d-example.jpg 885w", "/static/6b4d5804e9195100984b4ea31da1408d/fbd2c/1d-example.jpg 1180w", "/static/6b4d5804e9195100984b4ea31da1408d/652b0/1d-example.jpg 1765w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Vectors with a single dimension.`}</figcaption>{`
  `}</figure></p>
    <p>{`A single dimension is pretty limiting, though. `}</p>
    <p>{`Even if we go beyond a single dimension, we can continue to think of vectors as points in `}<em parentName="p">{`n`}</em>{`-dimensional space.`}</p>
    <h3 {...{
      "id": "2d",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#2d",
        "aria-label": "2d permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2D`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "csv"
    }}><pre parentName="div" {...{
        "className": "language-csv"
      }}><code parentName="pre" {...{
          "className": "language-csv"
        }}><span parentName="code" {...{
            "className": "token value"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`y`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`1`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`7`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`3`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`6`}</span></code></pre></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/bf76bcad0b777ab628f53af6c112f960/a90e7/2d-example.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "87.83783783783784%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAASABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/KMg0AD//EABcQAAMBAAAAAAAAAAAAAAAAAAABIBH/2gAIAQEAAQUCMhx//8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAFBABAAAAAAAAAAAAAAAAAAAAMP/aAAgBAQAGPwIf/8QAHBAAAQMFAAAAAAAAAAAAAAAAAAEQMREgIUFR/9oACAEBAAE/IZKOC4NtKz//2gAMAwEAAgADAAAAEBMIPP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EABoQAQACAwEAAAAAAAAAAAAAAAEAESExURD/2gAIAQEAAT8QTIdeZqq4II1LOkyw5KOSi3BADU//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"Vectors with 2 dimensions.\"",
              "title": "Vectors with 2 dimensions.",
              "src": "/static/bf76bcad0b777ab628f53af6c112f960/1c72d/2d-example.jpg",
              "srcSet": ["/static/bf76bcad0b777ab628f53af6c112f960/a80bd/2d-example.jpg 148w", "/static/bf76bcad0b777ab628f53af6c112f960/1c91a/2d-example.jpg 295w", "/static/bf76bcad0b777ab628f53af6c112f960/1c72d/2d-example.jpg 590w", "/static/bf76bcad0b777ab628f53af6c112f960/a8a14/2d-example.jpg 885w", "/static/bf76bcad0b777ab628f53af6c112f960/fbd2c/2d-example.jpg 1180w", "/static/bf76bcad0b777ab628f53af6c112f960/a90e7/2d-example.jpg 2022w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Vectors with 2 dimensions.`}</figcaption>{`
  `}</figure></p>
    <h3 {...{
      "id": "3d",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#3d",
        "aria-label": "3d permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3D`}</h3>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "csv"
    }}><pre parentName="div" {...{
        "className": "language-csv"
      }}><code parentName="pre" {...{
          "className": "language-csv"
        }}><span parentName="code" {...{
            "className": "token value"
          }}>{`x`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`y`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`z`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`2`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`7`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`5`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`3`}</span>{`
`}<span parentName="code" {...{
            "className": "token value"
          }}>{`5`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`6`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span><span parentName="code" {...{
            "className": "token value"
          }}>{`6`}</span></code></pre></div>
    <p><img parentName="p" {...{
        "src": "/412cfe49142ade774235a90caced97bb/3d-example.gif",
        "alt": "\"Vectors with 3 dimensions.\"",
        "title": "Vectors with 3 dimensions."
      }}></img></p>
    <h3 {...{
      "id": "n-d",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#n-d",
        "aria-label": "n d permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><em parentName="h3">{`n`}</em>{`-D`}</h3>
    <p>{`It's quite common to use 100s or 1000s of features (dimensions) to represent words and documents.`}</p>
    <p>{`Beyond 3 dimensions, though, vectors become less straighforward to visualize. Some options include using properties such as color and shape to represent additional dimensions.`}</p>
    {
      /* ## Cosine similarity
      $$
      \begin{aligned}
      cos(\mathbf{a}, \mathbf{b}) &= \frac{\mathbf{a} \cdot \mathbf{b}}{\vert \vert a \vert \vert_{2}  \times \vert \vert b \vert \vert_{2}}
      \\
      &= \frac{a \cdot b}{[\sum_{i=0}^{\vert a \vert} \text{abs}(a_{i})^{2}]^{\frac{1}{2}} \; \left[[\sum_{i=0}^{\vert b \vert} \text{abs}(b_{i})^{2}\right]^{\frac{1}{2}}} \\
      &= \frac{a \cdot b}{\sqrt{\sum_{i=0}^{\vert a \vert} \text{abs}(a_{i})^{2}]} \; \sqrt{\sum_{i=0}^{\vert b \vert} \text{abs}(b_{i})^{2}}}
      \end{aligned}
      $$
      
      $$
      \mathbf{a} = \begin{bmatrix}
      2 \\
      3 \\
      \end{bmatrix}
      \\
      \mathbf{b} = \begin{bmatrix}
      6 \\
      5 \\
      \end{bmatrix}
      \\
      \begin{aligned}
      cos(\mathbf{a}, \mathbf{b}) &= \frac{a \cdot b}{\vert \vert a \vert \vert \; \vert \vert b \vert \vert} \\
      &= \frac{a \cdot b}{\left[\sum_{i=0}^{\vert a \vert} \text{abs}(a_{i})^{2}\right]^{\frac{1}{2}} \; [\sum_{i=0}^{\vert b \vert} \text{abs}(b_{i})^{2}]^{\frac{1}{2}}} \\
      &= \frac{a \cdot b}{\sqrt{\sum_{i=0}^{\vert a \vert} \text{abs}(a_{i})^{2}]} \; \sqrt{\sum_{i=0}^{\vert b \vert} \text{abs}(b_{i})^{2}}}
      \\
      &= \frac{(2 \times 6) + (3 \times 5)}{\sqrt{2^{2} + 3^{2}} \; \sqrt{6^{2} + 5^{2}}}
      \\
      &= \frac{12 + 15}{\sqrt{4 + 9} \; \sqrt{36 + 25}}
      \\
      &= \frac{27}{\sqrt{13} \; \sqrt{61}}
      \\
      &= \frac{27}{3.6056 \times 7.8102}
      \\
      &= \frac{27}{28.1605}
      \\
      &=  0.9588
      \end{aligned}
      $$ */
    }
    <h1 {...{
      "id": "properties-of-vectors",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#properties-of-vectors",
        "aria-label": "properties of vectors permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Properties of vectors`}</h1>
    <p>{`All vectors have two components: `}</p>
    <ol>
      <li parentName="ol">{`length or `}<strong parentName="li">{`magnitude`}</strong></li>
      <li parentName="ol">{`direction`}</li>
    </ol>
    <h2 {...{
      "id": "magnitude",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#magnitude",
        "aria-label": "magnitude permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Magnitude`}</h2>
    <p>{`The `}<strong parentName="p">{`magnitude`}</strong>{` of a vector is its distance from the origin (i.e., the vector comprised of all zeros).  Though it can be zero in a special case, length is never negative.  `}</p>
    <p>{`For a vector `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{a}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span></span></span></span></span>{`, you'll often see `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\| \\mathbf{a} \\|`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span></span></span></span></span>{` used to refer to its magnitude.  If we're being more precise `}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`, we'll use `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "normal"
                    }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\| \\mathbf{a} \\|_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∥`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` to refer to the magnitude of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`a`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{a}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`a`}</span></span></span></span></span>{`.`}</p>
    <p>{`How do we calculate length?  One familiar way of thinking about this is to consider a 2D vector and apply the `}<a parentName="p" {...{
        "href": "https://www.khanacademy.org/math/geometry/hs-geo-analytic-geometry/hs-geo-distance-and-midpoints/v/distance-formula",
        "target": "_self",
        "rel": "nofollow"
      }}>{`distance formula`}</a>{` to it and the origin.  For this example, we'll consider the 2D vector `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`b`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`7`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{b} = \\begin{bmatrix} 4 & 7 \\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2em",
                  "verticalAlign": "-0.35em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`[`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`7`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`]`}</span></span></span></span></span></span></span>{`:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle">{`d`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><msqrt parentName="mrow"><mrow parentName="msqrt"><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mn parentName="mrow">{`4`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                                  "stretchy": "false"
                                }}>{`)`}</mo><mn parentName="msup">{`2`}</mn></msup><mo parentName="mrow">{`+`}</mo><mo parentName="mrow" {...{
                                "stretchy": "false"
                              }}>{`(`}</mo><mn parentName="mrow">{`7`}</mn><mo parentName="mrow">{`−`}</mo><mn parentName="mrow">{`0`}</mn><msup parentName="mrow"><mo parentName="msup" {...{
                                  "stretchy": "false"
                                }}>{`)`}</mo><mn parentName="msup">{`2`}</mn></msup></mrow></msqrt></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><msqrt parentName="mrow"><mrow parentName="msqrt"><mn parentName="mrow">{`16`}</mn><mo parentName="mrow">{`+`}</mo><mn parentName="mrow">{`49`}</mn></mrow></msqrt></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><msqrt parentName="mrow"><mn parentName="msqrt">{`65`}</mn></msqrt></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`≈`}</mo><mn parentName="mrow">{`8.06`}</mn></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∥`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`b`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                              "mathvariant": "normal"
                            }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`≈`}</mo><mn parentName="mrow">{`8.06`}</mn></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned}
d &= \\sqrt{(4 - 0)^{2} + (7 - 0)^{2}}
\\\\[2em]
&= \\sqrt{16 + 49} 
\\\\[2em]
&= \\sqrt{65} 
\\\\[2em]
&\\approx 8.06
\\\\[2em]
\\|\\mathbf{b} \\|_{2} &\\approx 8.06
\\end{aligned}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "15.8344em",
                  "verticalAlign": "-7.6672em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "8.1672em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-10.1833em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal"
                              }}>{`d`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-6.6089em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.9928em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "0.5072em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span><span parentName="span" {...{
                            "style": {
                              "top": "4.0072em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`∥`}</span><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`∥`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "7.6672em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "8.1672em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-10.1833em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord sqrt"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.9839em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "svg-align",
                                        "style": {
                                          "top": "-3.2em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3.2em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "mord",
                                          "style": {
                                            "paddingLeft": "1em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "mopen"
                                          }}>{`(`}</span><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`4`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mbin"
                                          }}>{`−`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`0`}</span><span parentName="span" {...{
                                            "className": "mclose"
                                          }}><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span><span parentName="span" {...{
                                              "className": "msupsub"
                                            }}><span parentName="span" {...{
                                                "className": "vlist-t"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-r"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist",
                                                    "style": {
                                                      "height": "0.7401em"
                                                    }
                                                  }}><span parentName="span" {...{
                                                      "style": {
                                                        "top": "-2.989em",
                                                        "marginRight": "0.05em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "className": "pstrut",
                                                        "style": {
                                                          "height": "2.7em"
                                                        }
                                                      }}></span><span parentName="span" {...{
                                                        "className": "sizing reset-size6 size3 mtight"
                                                      }}><span parentName="span" {...{
                                                          "className": "mord mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}>{`2`}</span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mbin"
                                          }}>{`+`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mopen"
                                          }}>{`(`}</span><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`7`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mbin"
                                          }}>{`−`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`0`}</span><span parentName="span" {...{
                                            "className": "mclose"
                                          }}><span parentName="span" {...{
                                              "className": "mclose"
                                            }}>{`)`}</span><span parentName="span" {...{
                                              "className": "msupsub"
                                            }}><span parentName="span" {...{
                                                "className": "vlist-t"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-r"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist",
                                                    "style": {
                                                      "height": "0.7401em"
                                                    }
                                                  }}><span parentName="span" {...{
                                                      "style": {
                                                        "top": "-2.989em",
                                                        "marginRight": "0.05em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "className": "pstrut",
                                                        "style": {
                                                          "height": "2.7em"
                                                        }
                                                      }}></span><span parentName="span" {...{
                                                        "className": "sizing reset-size6 size3 mtight"
                                                      }}><span parentName="span" {...{
                                                          "className": "mord mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}>{`2`}</span></span></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.9439em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3.2em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "hide-tail",
                                          "style": {
                                            "minWidth": "1.02em",
                                            "height": "1.28em"
                                          }
                                        }}><svg parentName="span" {...{
                                            "xmlns": "http://www.w3.org/2000/svg",
                                            "width": "400em",
                                            "height": "1.28em",
                                            "viewBox": "0 0 400000 1296",
                                            "preserveAspectRatio": "xMinYMin slice"
                                          }}><path parentName="svg" {...{
                                              "d": "M263,681c0.7,0,18,39.7,52,119\nc34,79.3,68.167,158.7,102.5,238c34.3,79.3,51.8,119.3,52.5,120\nc340,-704.7,510.7,-1060.3,512,-1067\nl0 -0\nc4.7,-7.3,11,-11,19,-11\nH40000v40H1012.3\ns-271.3,567,-271.3,567c-38.7,80.7,-84,175,-136,283c-52,108,-89.167,185.3,-111.5,232\nc-22.3,46.7,-33.8,70.3,-34.5,71c-4.7,4.7,-12.3,7,-23,7s-12,-1,-12,-1\ns-109,-253,-109,-253c-72.7,-168,-109.3,-252,-110,-252c-10.7,8,-22,16.7,-34,26\nc-22,17.3,-33.3,26,-34,26s-26,-26,-26,-26s76,-59,76,-59s76,-60,76,-60z\nM1001 80h400000v40h-400000z"
                                            }}></path></svg></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.2561em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-6.6089em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord sqrt"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.9144em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "svg-align",
                                        "style": {
                                          "top": "-3em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "mord",
                                          "style": {
                                            "paddingLeft": "0.833em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`16`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mbin"
                                          }}>{`+`}</span><span parentName="span" {...{
                                            "className": "mspace",
                                            "style": {
                                              "marginRight": "0.2222em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`49`}</span></span></span><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.8744em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "hide-tail",
                                          "style": {
                                            "minWidth": "0.853em",
                                            "height": "1.08em"
                                          }
                                        }}><svg parentName="span" {...{
                                            "xmlns": "http://www.w3.org/2000/svg",
                                            "width": "400em",
                                            "height": "1.08em",
                                            "viewBox": "0 0 400000 1080",
                                            "preserveAspectRatio": "xMinYMin slice"
                                          }}><path parentName="svg" {...{
                                              "d": "M95,702\nc-2.7,0,-7.17,-2.7,-13.5,-8c-5.8,-5.3,-9.5,-10,-9.5,-14\nc0,-2,0.3,-3.3,1,-4c1.3,-2.7,23.83,-20.7,67.5,-54\nc44.2,-33.3,65.8,-50.3,66.5,-51c1.3,-1.3,3,-2,5,-2c4.7,0,8.7,3.3,12,10\ns173,378,173,378c0.7,0,35.3,-71,104,-213c68.7,-142,137.5,-285,206.5,-429\nc69,-144,104.5,-217.7,106.5,-221\nl0 -0\nc5.3,-9.3,12,-14,20,-14\nH400000v40H845.2724\ns-225.272,467,-225.272,467s-235,486,-235,486c-2.7,4.7,-9,7,-19,7\nc-6,0,-10,-1,-12,-3s-194,-422,-194,-422s-65,47,-65,47z\nM834 80h400000v40h-400000z"
                                            }}></path></svg></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.1256em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-2.9928em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord sqrt"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.9561em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "svg-align",
                                        "style": {
                                          "top": "-3em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "mord",
                                          "style": {
                                            "paddingLeft": "0.833em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "mord"
                                          }}>{`65`}</span></span></span><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.9161em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "3em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "hide-tail",
                                          "style": {
                                            "minWidth": "0.853em",
                                            "height": "1.08em"
                                          }
                                        }}><svg parentName="span" {...{
                                            "xmlns": "http://www.w3.org/2000/svg",
                                            "width": "400em",
                                            "height": "1.08em",
                                            "viewBox": "0 0 400000 1080",
                                            "preserveAspectRatio": "xMinYMin slice"
                                          }}><path parentName="svg" {...{
                                              "d": "M95,702\nc-2.7,0,-7.17,-2.7,-13.5,-8c-5.8,-5.3,-9.5,-10,-9.5,-14\nc0,-2,0.3,-3.3,1,-4c1.3,-2.7,23.83,-20.7,67.5,-54\nc44.2,-33.3,65.8,-50.3,66.5,-51c1.3,-1.3,3,-2,5,-2c4.7,0,8.7,3.3,12,10\ns173,378,173,378c0.7,0,35.3,-71,104,-213c68.7,-142,137.5,-285,206.5,-429\nc69,-144,104.5,-217.7,106.5,-221\nl0 -0\nc5.3,-9.3,12,-14,20,-14\nH400000v40H845.2724\ns-225.272,467,-225.272,467s-235,486,-235,486c-2.7,4.7,-9,7,-19,7\nc-6,0,-10,-1,-12,-3s-194,-422,-194,-422s-65,47,-65,47z\nM834 80h400000v40h-400000z"
                                            }}></path></svg></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.0839em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "0.5072em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`≈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`8.06`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "4.0072em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`≈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`8.06`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "7.6672em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`A 3-dimensional point (vector) will work the same way `}<em parentName="p">{`as will any `}<span parentName="em" {...{
          "className": "math math-inline"
        }}><span parentName="span" {...{
            "className": "katex"
          }}><span parentName="span" {...{
              "className": "katex-mathml"
            }}><math parentName="span" {...{
                "xmlns": "http://www.w3.org/1998/Math/MathML"
              }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                    "encoding": "application/x-tex"
                  }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
              "className": "katex-html",
              "aria-hidden": "true"
            }}><span parentName="span" {...{
                "className": "base"
              }}><span parentName="span" {...{
                  "className": "strut",
                  "style": {
                    "height": "0.4306em"
                  }
                }}></span><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`n`}</span></span></span></span></span>{`-dimensional vector`}</em>{`.  `}</p>
    <h3 {...{
      "id": "norm",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#norm",
        "aria-label": "norm permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Norm`}</h3>
    <p>{`To calculate the magnitude (aka 2-norm) of `}<em parentName="p">{`any`}</em>{` vector, we can use the following form of the distance formula:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "normal"
                    }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub><mo parentName="mrow">{`=`}</mo><msqrt parentName="mrow"><mrow parentName="msqrt"><munderover parentName="mrow"><mo parentName="munderover">{`∑`}</mo><mrow parentName="munderover"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`0`}</mn></mrow><mrow parentName="munderover"><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∣`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`x`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∣`}</mi></mrow></munderover><msubsup parentName="mrow"><mi parentName="msubsup">{`x`}</mi><mi parentName="msubsup">{`i`}</mi><mn parentName="msubsup">{`2`}</mn></msubsup></mrow></msqrt></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\| \\mathbf{x} \\|_{2} = \\sqrt{\\sum_{i=0}^{\\vert \\mathbf{x} \\vert} x_{i}^{2}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∥`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.4664em",
                  "verticalAlign": "-1.2777em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord sqrt"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "2.1888em"
                      }
                    }}><span parentName="span" {...{
                        "className": "svg-align",
                        "style": {
                          "top": "-5.4264em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "5.4264em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "mord",
                          "style": {
                            "paddingLeft": "1.056em"
                          }
                        }}><span parentName="span" {...{
                            "className": "mop op-limits"
                          }}><span parentName="span" {...{
                              "className": "vlist-t vlist-t2"
                            }}><span parentName="span" {...{
                                "className": "vlist-r"
                              }}><span parentName="span" {...{
                                  "className": "vlist",
                                  "style": {
                                    "height": "1.961em"
                                  }
                                }}><span parentName="span" {...{
                                    "style": {
                                      "top": "-1.8723em",
                                      "marginLeft": "0em"
                                    }
                                  }}><span parentName="span" {...{
                                      "className": "pstrut",
                                      "style": {
                                        "height": "3.05em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "sizing reset-size6 size3 mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mtight"
                                      }}><span parentName="span" {...{
                                          "className": "mord mathnormal mtight"
                                        }}>{`i`}</span><span parentName="span" {...{
                                          "className": "mrel mtight"
                                        }}>{`=`}</span><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}>{`0`}</span></span></span></span><span parentName="span" {...{
                                    "style": {
                                      "top": "-3.05em"
                                    }
                                  }}><span parentName="span" {...{
                                      "className": "pstrut",
                                      "style": {
                                        "height": "3.05em"
                                      }
                                    }}></span><span parentName="span"><span parentName="span" {...{
                                        "className": "mop op-symbol large-op"
                                      }}>{`∑`}</span></span></span><span parentName="span" {...{
                                    "style": {
                                      "top": "-4.386em",
                                      "marginLeft": "0em"
                                    }
                                  }}><span parentName="span" {...{
                                      "className": "pstrut",
                                      "style": {
                                        "height": "3.05em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "sizing reset-size6 size3 mtight"
                                    }}><span parentName="span" {...{
                                        "className": "mord mtight"
                                      }}><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}>{`∣`}</span><span parentName="span" {...{
                                          "className": "mord mathbf mtight"
                                        }}>{`x`}</span><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}>{`∣`}</span></span></span></span></span><span parentName="span" {...{
                                  "className": "vlist-s"
                                }}>{`​`}</span></span><span parentName="span" {...{
                                "className": "vlist-r"
                              }}><span parentName="span" {...{
                                  "className": "vlist",
                                  "style": {
                                    "height": "1.2777em"
                                  }
                                }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                            "className": "mspace",
                            "style": {
                              "marginRight": "0.1667em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "msupsub"
                            }}><span parentName="span" {...{
                                "className": "vlist-t vlist-t2"
                              }}><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.7959em"
                                    }
                                  }}><span parentName="span" {...{
                                      "style": {
                                        "top": "-2.4231em",
                                        "marginLeft": "0em",
                                        "marginRight": "0.05em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.7em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "sizing reset-size6 size3 mtight"
                                      }}><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mathnormal mtight"
                                          }}>{`i`}</span></span></span></span><span parentName="span" {...{
                                      "style": {
                                        "top": "-3.0448em",
                                        "marginRight": "0.05em"
                                      }
                                    }}><span parentName="span" {...{
                                        "className": "pstrut",
                                        "style": {
                                          "height": "2.7em"
                                        }
                                      }}></span><span parentName="span" {...{
                                        "className": "sizing reset-size6 size3 mtight"
                                      }}><span parentName="span" {...{
                                          "className": "mord mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                    "className": "vlist-s"
                                  }}>{`​`}</span></span><span parentName="span" {...{
                                  "className": "vlist-r"
                                }}><span parentName="span" {...{
                                    "className": "vlist",
                                    "style": {
                                      "height": "0.2769em"
                                    }
                                  }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-4.1488em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "5.4264em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "hide-tail",
                          "style": {
                            "minWidth": "0.742em",
                            "height": "3.5064em"
                          }
                        }}><svg parentName="span" {...{
                            "xmlns": "http://www.w3.org/2000/svg",
                            "width": "400em",
                            "height": "3.5064em",
                            "viewBox": "0 0 400000 3506",
                            "preserveAspectRatio": "xMinYMin slice"
                          }}><path parentName="svg" {...{
                              "d": "M702 80H40000040\nH742v3372l-4 4-4 4c-.667.7 -2 1.5-4 2.5s-4.167 1.833-6.5 2.5-5.5 1-9.5 1\nh-12l-28-84c-16.667-52-96.667 -294.333-240-727l-212 -643 -85 170\nc-4-3.333-8.333-7.667-13 -13l-13-13l77-155 77-156c66 199.333 139 419.667\n219 661 l218 661zM702 80H400000v40H742z"
                            }}></path></svg></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.2777em"
                      }
                    }}><span parentName="span"></span></span></span></span></span></span></span></span></span></div>
    <h4 {...{
      "id": "unit-vector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#unit-vector",
        "aria-label": "unit vector permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Unit vector`}</h4>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`The unit vector `}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`A vector with a magnitude of 1 is called a `}<strong parentName="p">{`unit vector`}</strong>{`.`}</p></div></div>
    <h4 {...{
      "id": "mathematical-symbols-vert-mathbfx-vert",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mathematical-symbols-vert-mathbfx-vert",
        "aria-label": "mathematical symbols vert mathbfx vert permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mathematical symbols: `}<span parentName="h4" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∣`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\vert \\mathbf{x} \\vert`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`∣`}</span></span></span></span></span></h4>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`What does `}<span parentName="h5" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\vert \\mathbf{x} \\vert`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span></span></span></span></span>{` mean?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div"><span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\vert \\mathbf{x} \\vert`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span></span></span></span></span>{` is a way of specifying the `}<a parentName="p" {...{
            "href": "https://simple.wikipedia.org/wiki/Cardinality",
            "target": "_self",
            "rel": "nofollow"
          }}>{`cardinality`}</a>{` or size of the vector `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{x}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`x`}</span></span></span></span></span>{`.  For instance, if `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi><mo parentName="mrow">{`=`}</mo><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`[`}</mo><mn parentName="mrow">{`0`}</mn><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                        "separator": "true"
                      }}>{`,`}</mo><mn parentName="mrow">{`1`}</mn><mo parentName="mrow" {...{
                        "stretchy": "false"
                      }}>{`]`}</mo></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{x} = [0, 1, 1]`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mopen"
                  }}>{`[`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`0`}</span><span parentName="span" {...{
                    "className": "mpunct"
                  }}>{`,`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`1`}</span><span parentName="span" {...{
                    "className": "mpunct"
                  }}>{`,`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`1`}</span><span parentName="span" {...{
                    "className": "mclose"
                  }}>{`]`}</span></span></span></span></span>{`, then `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`3`}</mn></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\vert \\mathbf{x} \\vert = 3`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1em",
                      "verticalAlign": "-0.25em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`x`}</span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`∣`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.6444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`3`}</span></span></span></span></span>{`.  `}</p><p parentName="div">{`Let's look at an example using Python.`}</p><ol parentName="div">
          <li parentName="ol">{`Open the `}<a parentName="li" {...{
              "href": "https://ipython.org/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`iPython REPL`}</a>{` using a Docker container:`}</li>
        </ol><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "command-line-prompt"
              }}><span parentName="span" {...{
                  "data-user": "hahnpowell",
                  "data-host": "gubuntu"
                }}></span></span><span parentName="code" {...{
                "className": "token function"
              }}>{`docker`}</span>{` run -it `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"parsertongue/python:latest"`}</span>{` ipython`}</code></pre></div><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "language-python"
          }}><code parentName="pre" {...{
              "className": "language-python"
            }}>{`x `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`2`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`3`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`4`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{`

cardinality `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`lambda`}</span>{` vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token builtin"
              }}>{`len`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

`}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`assert`}</span>{` cardinality`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`x`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`==`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`5`}</span></code></pre></div><ol parentName="div" {...{
          "start": 2
        }}>
          <li parentName="ol">{`Exit the iPython REPL and terminate the docker container:`}</li>
        </ol><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "command-line-prompt"
              }}><span parentName="span" {...{
                  "data-user": "hahnpowell",
                  "data-host": "gubuntu"
                }}></span></span><span parentName="code" {...{
                "className": "token builtin class-name"
              }}>{`exit`}</span></code></pre></div></div></div>
    <h4 {...{
      "id": "mathematical-symbols-sigma",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#mathematical-symbols-sigma",
        "aria-label": "mathematical symbols sigma permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mathematical symbols: `}<span parentName="h4" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`Σ`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\Sigma`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6833em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`Σ`}</span></span></span></span></span></h4>
    <div {...{
      "className": "admonition admonition-note alert alert--secondary"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M6.3 5.69a.942.942 0 0 1-.28-.7c0-.28.09-.52.28-.7.19-.18.42-.28.7-.28.28 0 .52.09.7.28.18.19.28.42.28.7 0 .28-.09.52-.28.7a1 1 0 0 1-.7.3c-.28 0-.52-.11-.7-.3zM8 7.99c-.02-.25-.11-.48-.31-.69-.2-.19-.42-.3-.69-.31H6c-.27.02-.48.13-.69.31-.2.2-.3.44-.31.69h1v3c.02.27.11.5.31.69.2.2.42.31.69.31h1c.27 0 .48-.11.69-.31.2-.19.3-.42.31-.69H8V7.98v.01zM7 2.3c-3.14 0-5.7 2.54-5.7 5.68 0 3.14 2.56 5.7 5.7 5.7s5.7-2.55 5.7-5.7c0-3.15-2.56-5.69-5.7-5.69v.01zM7 .98c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.12-7-7 3.14-7 7-7z"
              }}></path></svg></span>{`What does `}<span parentName="h5" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Σ`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\Sigma`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.6833em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`Σ`}</span></span></span></span></span>{` mean?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`The `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`Σ`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\Sigma`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.6833em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}>{`Σ`}</span></span></span></span></span>{` ("sigma") symbol is just a way of denoting a summation.  For instance, `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><msubsup parentName="mrow"><mo parentName="msubsup">{`∑`}</mo><mrow parentName="msubsup"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`0`}</mn></mrow><mrow parentName="msubsup"><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∣`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`x`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∣`}</mi></mrow></msubsup><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\sum_{i=0}^{\\vert \\mathbf{x} \\vert}x_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1.3276em",
                      "verticalAlign": "-0.2997em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mop"
                  }}><span parentName="span" {...{
                      "className": "mop op-symbol small-op",
                      "style": {
                        "position": "relative",
                        "top": "0em"
                      }
                    }}>{`∑`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.0279em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.4003em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`i`}</span><span parentName="span" {...{
                                    "className": "mrel mtight"
                                  }}>{`=`}</span><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`0`}</span></span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.2029em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`∣`}</span><span parentName="span" {...{
                                    "className": "mord mathbf mtight"
                                  }}>{`x`}</span><span parentName="span" {...{
                                    "className": "mord mtight"
                                  }}>{`∣`}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.2997em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.1667em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord"
                  }}><span parentName="span" {...{
                      "className": "mord mathnormal"
                    }}>{`x`}</span><span parentName="span" {...{
                      "className": "msupsub"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.3117em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-2.55em",
                                "marginLeft": "0em",
                                "marginRight": "0.05em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "2.7em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "sizing reset-size6 size3 mtight"
                              }}><span parentName="span" {...{
                                  "className": "mord mtight"
                                }}><span parentName="span" {...{
                                    "className": "mord mathnormal mtight"
                                  }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.15em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` simply means to sum all number in a vector `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`x`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`x`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4306em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`x`}</span></span></span></span></span>{` from the 0 index until the last element of the vector.`}</p><p parentName="div">{`Let's look at an example using Python.`}</p><ol parentName="div">
          <li parentName="ol">{`Open the `}<a parentName="li" {...{
              "href": "https://ipython.org/",
              "target": "_self",
              "rel": "nofollow"
            }}>{`iPython REPL`}</a>{` using a Docker container:`}</li>
        </ol><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "command-line-prompt"
              }}><span parentName="span" {...{
                  "data-user": "hahnpowell",
                  "data-host": "gubuntu"
                }}></span></span><span parentName="code" {...{
                "className": "token function"
              }}>{`docker`}</span>{` run -it `}<span parentName="code" {...{
                "className": "token string"
              }}>{`"parsertongue/python:latest"`}</span>{` ipython`}</code></pre></div><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "python"
        }}><pre parentName="div" {...{
            "className": "language-python"
          }}><code parentName="pre" {...{
              "className": "language-python"
            }}>{`x `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`[`}</span><span parentName="code" {...{
                "className": "token number"
              }}>{`1`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`2`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`3`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`4`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`,`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`5`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`]`}</span>{`

sigma `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`=`}</span>{` `}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`lambda`}</span>{` vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`:`}</span>{` `}<span parentName="code" {...{
                "className": "token builtin"
              }}>{`sum`}</span><span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`vec`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{`

`}<span parentName="code" {...{
                "className": "token keyword"
              }}>{`assert`}</span>{` sigma`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`(`}</span>{`x`}<span parentName="code" {...{
                "className": "token punctuation"
              }}>{`)`}</span>{` `}<span parentName="code" {...{
                "className": "token operator"
              }}>{`==`}</span>{` `}<span parentName="code" {...{
                "className": "token number"
              }}>{`15`}</span></code></pre></div><ol parentName="div" {...{
          "start": 2
        }}>
          <li parentName="ol">{`Exit the iPython REPL and terminate the docker container:`}</li>
        </ol><div parentName="div" {...{
          "className": "gatsby-highlight",
          "data-language": "bash"
        }}><pre parentName="div" {...{
            "className": "language-bash"
          }}><code parentName="pre" {...{
              "className": "language-bash"
            }}><span parentName="code" {...{
                "className": "command-line-prompt"
              }}><span parentName="span" {...{
                  "data-user": "hahnpowell",
                  "data-host": "gubuntu"
                }}></span></span><span parentName="code" {...{
                "className": "token builtin class-name"
              }}>{`exit`}</span></code></pre></div></div></div>
    <h2 {...{
      "id": "direction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#direction",
        "aria-label": "direction permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Direction`}</h2>
    <p>{`The other component of a vector is its `}<strong parentName="p">{`direction`}</strong>{`.  `}</p>
    <p>{`To calculate direction, we need to normalize a vector `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{x}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span></span></span></span></span>{` by `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                      "mathvariant": "normal"
                    }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\|\\mathbf{x}\\|_{2}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`∥`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3011em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mtight"
                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{`.  We do this by dividing each element `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`x_{i}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.5806em",
                  "verticalAlign": "-0.15em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span>{` of `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{x}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span></span></span></span></span>{` by `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><mi parentName="mrow" {...{
                    "mathvariant": "normal"
                  }}>{`∥`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\|\\mathbf{x}\\|`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mord"
              }}>{`∥`}</span></span></span></span></span>{`.  As an example, consider again the 2D vector `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`b`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`7`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{b} = \\begin{bmatrix} 4 & 7 \\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2em",
                  "verticalAlign": "-0.35em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`[`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`7`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`]`}</span></span></span></span></span></span></span>{`:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mi parentName="mstyle" {...{
                          "mathvariant": "bold"
                        }}>{`b`}</mi></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`7`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mi parentName="mrow" {...{
                            "mathvariant": "normal"
                          }}>{`∥`}</mi><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`b`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                              "mathvariant": "normal"
                            }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`≈`}</mo><mn parentName="mrow">{`8.06`}</mn></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mfrac parentName="mstyle"><mi parentName="mfrac" {...{
                            "mathvariant": "bold"
                          }}>{`b`}</mi><mrow parentName="mfrac"><mi parentName="mrow" {...{
                              "mathvariant": "normal"
                            }}>{`∥`}</mi><mi parentName="mrow" {...{
                              "mathvariant": "bold"
                            }}>{`b`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                                "mathvariant": "normal"
                              }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mfrac></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mfrac parentName="mstyle"><mn parentName="mfrac">{`4`}</mn><mrow parentName="mfrac"><mi parentName="mrow" {...{
                                          "mathvariant": "normal"
                                        }}>{`∥`}</mi><mi parentName="mrow" {...{
                                          "mathvariant": "bold"
                                        }}>{`b`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                                            "mathvariant": "normal"
                                          }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mfrac></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mfrac parentName="mstyle"><mn parentName="mfrac">{`7`}</mn><mrow parentName="mfrac"><mi parentName="mrow" {...{
                                          "mathvariant": "normal"
                                        }}>{`∥`}</mi><mi parentName="mrow" {...{
                                          "mathvariant": "bold"
                                        }}>{`b`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                                            "mathvariant": "normal"
                                          }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mfrac></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mtext parentName="mrow">{`norm`}</mtext><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`(`}</mo><mi parentName="mrow" {...{
                            "mathvariant": "bold"
                          }}>{`b`}</mi><mo parentName="mrow" {...{
                            "stretchy": "false"
                          }}>{`)`}</mo></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`≈`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`0.496`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`0.868`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned}

\\mathbf{b} &= \\begin{bmatrix}
4 & 7 \\\\
\\end{bmatrix}

\\\\[2em]

\\|\\mathbf{b} \\|_{2} &\\approx 8.06

\\\\[2em]

\\frac{\\mathbf{b}}{\\|\\mathbf{b} \\|_{2}} &= \\begin{bmatrix}
\\frac{4}{\\|\\mathbf{b} \\|_{2}} & \\frac{7}{\\|\\mathbf{b} \\|_{2}} \\\\ 
\\end{bmatrix}

\\\\[2em]

\\text{norm}(\\mathbf{b}) &\\approx \\begin{bmatrix}
0.496 & 0.868 \\\\
\\end{bmatrix}

\\end{aligned}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "12.5514em",
                  "verticalAlign": "-6.0257em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "6.5257em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-9.0472em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`b`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-5.5472em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`∥`}</span><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`∥`}</span><span parentName="span" {...{
                                  "className": "msupsub"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.3011em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.55em",
                                            "marginLeft": "0em",
                                            "marginRight": "0.05em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "2.7em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "sizing reset-size6 size3 mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}><span parentName="span" {...{
                                                "className": "mord mtight"
                                              }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.15em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.5157em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mopen nulldelimiter"
                                }}></span><span parentName="span" {...{
                                  "className": "mfrac"
                                }}><span parentName="span" {...{
                                    "className": "vlist-t vlist-t2"
                                  }}><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "1.3714em"
                                        }
                                      }}><span parentName="span" {...{
                                          "style": {
                                            "top": "-2.314em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mord"
                                            }}>{`∥`}</span><span parentName="span" {...{
                                              "className": "mord mathbf"
                                            }}>{`b`}</span><span parentName="span" {...{
                                              "className": "mord"
                                            }}><span parentName="span" {...{
                                                "className": "mord"
                                              }}>{`∥`}</span><span parentName="span" {...{
                                                "className": "msupsub"
                                              }}><span parentName="span" {...{
                                                  "className": "vlist-t vlist-t2"
                                                }}><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.3011em"
                                                      }
                                                    }}><span parentName="span" {...{
                                                        "style": {
                                                          "top": "-2.55em",
                                                          "marginLeft": "0em",
                                                          "marginRight": "0.05em"
                                                        }
                                                      }}><span parentName="span" {...{
                                                          "className": "pstrut",
                                                          "style": {
                                                            "height": "2.7em"
                                                          }
                                                        }}></span><span parentName="span" {...{
                                                          "className": "sizing reset-size6 size3 mtight"
                                                        }}><span parentName="span" {...{
                                                            "className": "mord mtight"
                                                          }}><span parentName="span" {...{
                                                              "className": "mord mtight"
                                                            }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                      "className": "vlist-s"
                                                    }}>{`​`}</span></span><span parentName="span" {...{
                                                    "className": "vlist-r"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist",
                                                      "style": {
                                                        "height": "0.15em"
                                                      }
                                                    }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.23em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "frac-line",
                                            "style": {
                                              "borderBottomWidth": "0.04em"
                                            }
                                          }}></span></span><span parentName="span" {...{
                                          "style": {
                                            "top": "-3.677em"
                                          }
                                        }}><span parentName="span" {...{
                                            "className": "pstrut",
                                            "style": {
                                              "height": "3em"
                                            }
                                          }}></span><span parentName="span" {...{
                                            "className": "mord"
                                          }}><span parentName="span" {...{
                                              "className": "mord mathbf"
                                            }}>{`b`}</span></span></span></span><span parentName="span" {...{
                                        "className": "vlist-s"
                                      }}>{`​`}</span></span><span parentName="span" {...{
                                      "className": "vlist-r"
                                    }}><span parentName="span" {...{
                                        "className": "vlist",
                                        "style": {
                                          "height": "0.936em"
                                        }
                                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose nulldelimiter"
                                }}></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "1.9943em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord text"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`norm`}</span></span><span parentName="span" {...{
                                "className": "mopen"
                              }}>{`(`}</span><span parentName="span" {...{
                                "className": "mord mathbf"
                              }}>{`b`}</span><span parentName="span" {...{
                                "className": "mclose"
                              }}>{`)`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "6.0257em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "6.5257em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-9.0472em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`7`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-5.5472em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`≈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}>{`8.06`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.5157em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size2"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.9326em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.0874em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}><span parentName="span" {...{
                                                    "className": "mopen nulldelimiter"
                                                  }}></span><span parentName="span" {...{
                                                    "className": "mfrac"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist-t vlist-t2"
                                                    }}><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.8451em"
                                                          }
                                                        }}><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-2.655em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`∥`}</span><span parentName="span" {...{
                                                                  "className": "mord mathbf mtight"
                                                                }}>{`b`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}><span parentName="span" {...{
                                                                    "className": "mord mtight"
                                                                  }}>{`∥`}</span><span parentName="span" {...{
                                                                    "className": "msupsub"
                                                                  }}><span parentName="span" {...{
                                                                      "className": "vlist-t vlist-t2"
                                                                    }}><span parentName="span" {...{
                                                                        "className": "vlist-r"
                                                                      }}><span parentName="span" {...{
                                                                          "className": "vlist",
                                                                          "style": {
                                                                            "height": "0.3173em"
                                                                          }
                                                                        }}><span parentName="span" {...{
                                                                            "style": {
                                                                              "top": "-2.357em",
                                                                              "marginLeft": "0em",
                                                                              "marginRight": "0.0714em"
                                                                            }
                                                                          }}><span parentName="span" {...{
                                                                              "className": "pstrut",
                                                                              "style": {
                                                                                "height": "2.5em"
                                                                              }
                                                                            }}></span><span parentName="span" {...{
                                                                              "className": "sizing reset-size3 size1 mtight"
                                                                            }}><span parentName="span" {...{
                                                                                "className": "mord mtight"
                                                                              }}><span parentName="span" {...{
                                                                                  "className": "mord mtight"
                                                                                }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                                          "className": "vlist-s"
                                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                                        "className": "vlist-r"
                                                                      }}><span parentName="span" {...{
                                                                          "className": "vlist",
                                                                          "style": {
                                                                            "height": "0.143em"
                                                                          }
                                                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.23em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "frac-line",
                                                              "style": {
                                                                "borderBottomWidth": "0.04em"
                                                              }
                                                            }}></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.394em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`4`}</span></span></span></span></span><span parentName="span" {...{
                                                          "className": "vlist-s"
                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.52em"
                                                          }
                                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                                    "className": "mclose nulldelimiter"
                                                  }}></span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.4326em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.9326em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.0874em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}><span parentName="span" {...{
                                                    "className": "mopen nulldelimiter"
                                                  }}></span><span parentName="span" {...{
                                                    "className": "mfrac"
                                                  }}><span parentName="span" {...{
                                                      "className": "vlist-t vlist-t2"
                                                    }}><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.8451em"
                                                          }
                                                        }}><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-2.655em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`∥`}</span><span parentName="span" {...{
                                                                  "className": "mord mathbf mtight"
                                                                }}>{`b`}</span><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}><span parentName="span" {...{
                                                                    "className": "mord mtight"
                                                                  }}>{`∥`}</span><span parentName="span" {...{
                                                                    "className": "msupsub"
                                                                  }}><span parentName="span" {...{
                                                                      "className": "vlist-t vlist-t2"
                                                                    }}><span parentName="span" {...{
                                                                        "className": "vlist-r"
                                                                      }}><span parentName="span" {...{
                                                                          "className": "vlist",
                                                                          "style": {
                                                                            "height": "0.3173em"
                                                                          }
                                                                        }}><span parentName="span" {...{
                                                                            "style": {
                                                                              "top": "-2.357em",
                                                                              "marginLeft": "0em",
                                                                              "marginRight": "0.0714em"
                                                                            }
                                                                          }}><span parentName="span" {...{
                                                                              "className": "pstrut",
                                                                              "style": {
                                                                                "height": "2.5em"
                                                                              }
                                                                            }}></span><span parentName="span" {...{
                                                                              "className": "sizing reset-size3 size1 mtight"
                                                                            }}><span parentName="span" {...{
                                                                                "className": "mord mtight"
                                                                              }}><span parentName="span" {...{
                                                                                  "className": "mord mtight"
                                                                                }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                                                          "className": "vlist-s"
                                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                                        "className": "vlist-r"
                                                                      }}><span parentName="span" {...{
                                                                          "className": "vlist",
                                                                          "style": {
                                                                            "height": "0.143em"
                                                                          }
                                                                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.23em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "frac-line",
                                                              "style": {
                                                                "borderBottomWidth": "0.04em"
                                                              }
                                                            }}></span></span><span parentName="span" {...{
                                                            "style": {
                                                              "top": "-3.394em"
                                                            }
                                                          }}><span parentName="span" {...{
                                                              "className": "pstrut",
                                                              "style": {
                                                                "height": "3em"
                                                              }
                                                            }}></span><span parentName="span" {...{
                                                              "className": "sizing reset-size6 size3 mtight"
                                                            }}><span parentName="span" {...{
                                                                "className": "mord mtight"
                                                              }}><span parentName="span" {...{
                                                                  "className": "mord mtight"
                                                                }}>{`7`}</span></span></span></span></span><span parentName="span" {...{
                                                          "className": "vlist-s"
                                                        }}>{`​`}</span></span><span parentName="span" {...{
                                                        "className": "vlist-r"
                                                      }}><span parentName="span" {...{
                                                          "className": "vlist",
                                                          "style": {
                                                            "height": "0.52em"
                                                          }
                                                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                                    "className": "mclose nulldelimiter"
                                                  }}></span></span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.4326em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size2"
                                  }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "1.9943em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.3714em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`≈`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`0.496`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`0.868`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "6.0257em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p>{`This resulting vector is known as a `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Unit_vector",
        "target": "_self",
        "rel": "nofollow"
      }}><strong parentName="a">{`unit vector`}</strong></a>{`.  It contains information solely about the `}<strong parentName="p">{`direction`}</strong>{` of the vector.`}</p>
    <p>{`We can can simplify the formula for normalizing a vector `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{x}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.4444em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span></span></span></span></span>{` as ...`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mtext parentName="mrow">{`norm`}</mtext><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`(`}</mo><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`x`}</mi><mo parentName="mrow" {...{
                    "stretchy": "false"
                  }}>{`)`}</mo><mo parentName="mrow">{`=`}</mo><mfrac parentName="mrow"><mi parentName="mfrac" {...{
                      "mathvariant": "bold"
                    }}>{`x`}</mi><mrow parentName="mfrac"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∥`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`x`}</mi><msub parentName="mrow"><mi parentName="msub" {...{
                          "mathvariant": "normal"
                        }}>{`∥`}</mi><mn parentName="msub">{`2`}</mn></msub></mrow></mfrac></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\text{norm}(\\mathbf{x}) = \\frac{\\mathbf{x}}{\\| \\mathbf{x}\\|_{2}}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1em",
                  "verticalAlign": "-0.25em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord text"
              }}><span parentName="span" {...{
                  "className": "mord"
                }}>{`norm`}</span></span><span parentName="span" {...{
                "className": "mopen"
              }}>{`(`}</span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`x`}</span><span parentName="span" {...{
                "className": "mclose"
              }}>{`)`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "2.0574em",
                  "verticalAlign": "-0.936em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mopen nulldelimiter"
                }}></span><span parentName="span" {...{
                  "className": "mfrac"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "1.1214em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.314em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord"
                            }}>{`∥`}</span><span parentName="span" {...{
                              "className": "mord mathbf"
                            }}>{`x`}</span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`∥`}</span><span parentName="span" {...{
                                "className": "msupsub"
                              }}><span parentName="span" {...{
                                  "className": "vlist-t vlist-t2"
                                }}><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.3011em"
                                      }
                                    }}><span parentName="span" {...{
                                        "style": {
                                          "top": "-2.55em",
                                          "marginLeft": "0em",
                                          "marginRight": "0.05em"
                                        }
                                      }}><span parentName="span" {...{
                                          "className": "pstrut",
                                          "style": {
                                            "height": "2.7em"
                                          }
                                        }}></span><span parentName="span" {...{
                                          "className": "sizing reset-size6 size3 mtight"
                                        }}><span parentName="span" {...{
                                            "className": "mord mtight"
                                          }}><span parentName="span" {...{
                                              "className": "mord mtight"
                                            }}>{`2`}</span></span></span></span></span><span parentName="span" {...{
                                      "className": "vlist-s"
                                    }}>{`​`}</span></span><span parentName="span" {...{
                                    "className": "vlist-r"
                                  }}><span parentName="span" {...{
                                      "className": "vlist",
                                      "style": {
                                        "height": "0.15em"
                                      }
                                    }}><span parentName="span"></span></span></span></span></span></span></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.23em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "frac-line",
                            "style": {
                              "borderBottomWidth": "0.04em"
                            }
                          }}></span></span><span parentName="span" {...{
                          "style": {
                            "top": "-3.677em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "3em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "mord"
                          }}><span parentName="span" {...{
                              "className": "mord mathbf"
                            }}>{`x`}</span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.936em"
                        }
                      }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose nulldelimiter"
                }}></span></span></span></span></span></span></div>
    {
      /* # Cosine
      Think back to trigonometry ([SOH**CAH**TOA](https://en.wikipedia.org/wiki/Mnemonics_in_trigonometry)).
      $$
      cos(\theta) = \frac{\vert adjacent \vert}{\vert hypotenuse \vert}
      $$ */
    }
    <h1 {...{
      "id": "operations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#operations",
        "aria-label": "operations permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Operations`}</h1>
    <p>{`We can perform operations such as addition, subtraction, multiplication, and division on a vector and a `}<strong parentName="p">{`scalar`}</strong>{` or a vector and another vector.`}</p>
    <h2 {...{
      "id": "scalar-and-vector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#scalar-and-vector",
        "aria-label": "scalar and vector permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Scalar and vector`}</h2>
    <h3 {...{
      "id": "what-is-a-scalar",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-a-scalar",
        "aria-label": "what is a scalar permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is a scalar?`}</h3>
    <p>{`A `}<strong parentName="p">{`scalar`}</strong>{` is a number that `}<em parentName="p">{`scales`}</em>{` a vector.`}</p>
    <p>{`Say we have a two dimensional vector, `}<span parentName="p" {...{
        "className": "math math-inline"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`b`}</mi></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{b}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`b`}</span></span></span></span></span>{`:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                    "mathvariant": "bold"
                  }}>{`b`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\mathbf{b} = 
\\begin{bmatrix}
2 & 3 \\\\
\\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "0.6944em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord mathbf"
              }}>{`b`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "1.2em",
                  "verticalAlign": "-0.35em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`[`}</span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "arraycolsep",
                      "style": {
                        "width": "0.5em"
                      }
                    }}></span><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.85em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord"
                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "0.35em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose delimcenter",
                  "style": {
                    "top": "0em"
                  }
                }}><span parentName="span" {...{
                    "className": "delimsizing size1"
                  }}>{`]`}</span></span></span></span></span></span></span></div>
    <p>{`We can visualize it as a single point:`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/a2d71/2d-unscaled.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "93.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe/ZQDGgA//EABcQAAMBAAAAAAAAAAAAAAAAAAEQIDH/2gAIAQEAAQUCoYv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAXEAADAQAAAAAAAAAAAAAAAAABEBEg/9oACAEBAAE/IRui/9oADAMBAAIAAwAAABAACDz/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/EB//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/EB//xAAcEAABBAMBAAAAAAAAAAAAAAABABARcSFhkUH/2gAIAQEAAT8Q9W4yDbhIEkydqG+qL6v/2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"2D vector b.\"",
              "title": "2D vector **b** (unscaled).",
              "src": "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/1c72d/2d-unscaled.jpg",
              "srcSet": ["/static/3642a4fd444d2e0e41ecf5b0ced5de4f/a80bd/2d-unscaled.jpg 148w", "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/1c91a/2d-unscaled.jpg 295w", "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/1c72d/2d-unscaled.jpg 590w", "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/a8a14/2d-unscaled.jpg 885w", "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/fbd2c/2d-unscaled.jpg 1180w", "/static/3642a4fd444d2e0e41ecf5b0ced5de4f/a2d71/2d-unscaled.jpg 2091w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`2D vector **b** (unscaled).`}</figcaption>{`
  `}</figure></p>
    <p>{`If multiply this vector by the scalar `}<strong parentName="p">{`2`}</strong>{`, we get the following result:`}</p>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mtable parentName="semantics" {...{
                  "rowspacing": "0.25em",
                  "columnalign": "right left",
                  "columnspacing": "0em"
                }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mn parentName="mrow">{`2`}</mn><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mrow parentName="mstyle"><mn parentName="mrow">{`2`}</mn><mo parentName="mrow">{`×`}</mo><mn parentName="mrow">{`2`}</mn></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mrow parentName="mstyle"><mn parentName="mrow">{`2`}</mn><mo parentName="mrow">{`×`}</mo><mn parentName="mrow">{`3`}</mn></mrow></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"></mrow></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                        "scriptlevel": "0",
                        "displaystyle": "true"
                      }}><mrow parentName="mstyle"><mrow parentName="mrow"></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`[`}</mo><mtable parentName="mrow" {...{
                              "rowspacing": "0.16em",
                              "columnalign": "center center",
                              "columnspacing": "1em"
                            }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`4`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                    "scriptlevel": "0",
                                    "displaystyle": "false"
                                  }}><mn parentName="mstyle">{`6`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                              "fence": "true"
                            }}>{`]`}</mo></mrow></mrow></mstyle></mtd></mtr></mtable><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{aligned}

2 

\\begin{bmatrix}
2 & 3 \\\\
\\end{bmatrix}

&= 

\\begin{bmatrix}
2 \\times 2 & 2 \\times 3 \\\\
\\end{bmatrix}

\\\\[2em]

&=

\\begin{bmatrix}
4 & 6 \\\\
\\end{bmatrix}

\\end{aligned}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "5.02em",
                  "verticalAlign": "-2.26em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mtable"
                }}><span parentName="span" {...{
                    "className": "col-align-r"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.76em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-4.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}>{`2`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.1667em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.4em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.26em"
                          }
                        }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                    "className": "col-align-l"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.76em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-4.91em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.2222em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mbin"
                                                }}>{`×`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.2222em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`2`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.2222em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mbin"
                                                }}>{`×`}</span><span parentName="span" {...{
                                                  "className": "mspace",
                                                  "style": {
                                                    "marginRight": "0.2222em"
                                                  }
                                                }}></span><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-1.4em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "mord"
                            }}><span parentName="span" {...{
                                "className": "mord"
                              }}></span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mrel"
                              }}>{`=`}</span><span parentName="span" {...{
                                "className": "mspace",
                                "style": {
                                  "marginRight": "0.2778em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "minner"
                              }}><span parentName="span" {...{
                                  "className": "mopen delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`[`}</span></span><span parentName="span" {...{
                                  "className": "mord"
                                }}><span parentName="span" {...{
                                    "className": "mtable"
                                  }}><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`4`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "arraycolsep",
                                      "style": {
                                        "width": "0.5em"
                                      }
                                    }}></span><span parentName="span" {...{
                                      "className": "col-align-c"
                                    }}><span parentName="span" {...{
                                        "className": "vlist-t vlist-t2"
                                      }}><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.85em"
                                            }
                                          }}><span parentName="span" {...{
                                              "style": {
                                                "top": "-3.01em"
                                              }
                                            }}><span parentName="span" {...{
                                                "className": "pstrut",
                                                "style": {
                                                  "height": "3em"
                                                }
                                              }}></span><span parentName="span" {...{
                                                "className": "mord"
                                              }}><span parentName="span" {...{
                                                  "className": "mord"
                                                }}>{`6`}</span></span></span></span><span parentName="span" {...{
                                            "className": "vlist-s"
                                          }}>{`​`}</span></span><span parentName="span" {...{
                                          "className": "vlist-r"
                                        }}><span parentName="span" {...{
                                            "className": "vlist",
                                            "style": {
                                              "height": "0.35em"
                                            }
                                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                                  "className": "mclose delimcenter",
                                  "style": {
                                    "top": "0em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "delimsizing size1"
                                  }}>{`]`}</span></span></span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.26em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "590px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/c125527640d094c1f91bbbe9297a6e0d/a2d71/2d-scaled.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "93.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAATABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAIBBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAe+aARQAf//EABcQAAMBAAAAAAAAAAAAAAAAAAEQIDH/2gAIAQEAAQUCoYv/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAEDAQE/AR//xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAUEAEAAAAAAAAAAAAAAAAAAAAw/9oACAEBAAY/Ah//xAAXEAADAQAAAAAAAAAAAAAAAAABEBEg/9oACAEBAAE/IQxii//aAAwDAQACAAMAAAAQQAg8/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAHRAAAQQCAwAAAAAAAAAAAAAAAQARYXEQkSExQf/aAAgBAQABPxD1a5eMdDeQcCSXMpk7TXtf/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "\"2D vector 2b (scaled).\"",
              "title": "2D vector **2b** (scaled).",
              "src": "/static/c125527640d094c1f91bbbe9297a6e0d/1c72d/2d-scaled.jpg",
              "srcSet": ["/static/c125527640d094c1f91bbbe9297a6e0d/a80bd/2d-scaled.jpg 148w", "/static/c125527640d094c1f91bbbe9297a6e0d/1c91a/2d-scaled.jpg 295w", "/static/c125527640d094c1f91bbbe9297a6e0d/1c72d/2d-scaled.jpg 590w", "/static/c125527640d094c1f91bbbe9297a6e0d/a8a14/2d-scaled.jpg 885w", "/static/c125527640d094c1f91bbbe9297a6e0d/fbd2c/2d-scaled.jpg 1180w", "/static/c125527640d094c1f91bbbe9297a6e0d/a2d71/2d-scaled.jpg 2091w"],
              "sizes": "(max-width: 590px) 100vw, 590px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`2D vector **2b** (scaled).`}</figcaption>{`
  `}</figure></p>
    <h2 {...{
      "id": "vector-and-vector",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#vector-and-vector",
        "aria-label": "vector and vector permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Vector and vector`}</h2>
    <p>{`Vector-vector operations rely on the two vectors having the same shape (dimensionality).  These operations are performed against pairs of elements in the two vectors that share the same position (index).  Let's look at a couple of examples ...`}</p>
    <h3 {...{
      "id": "subtraction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#subtraction",
        "aria-label": "subtraction permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Subtraction`}</h3>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`a`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`b`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`c`}</mi></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow><mo parentName="mrow">{`⋅`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`d`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`e`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`f`}</mi></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`d`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`e`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow">{`−`}</mo><mi parentName="mrow">{`f`}</mi></mrow></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{bmatrix}
a \\\\
b \\\\
c \\\\
\\end{bmatrix}

\\cdot

\\begin{bmatrix}
d \\\\
e \\\\
f \\\\
\\end{bmatrix}

=

\\begin{bmatrix}
a - d \\\\
b - e \\\\
c - f \\\\
\\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`b`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`⋅`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`d`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`e`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.10764em"
                                  }
                                }}>{`f`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`d`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`b`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`e`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`−`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.10764em"
                                  }
                                }}>{`f`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <h4 {...{
      "id": "using-numpy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-numpy",
        "aria-label": "using numpy permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using NumPy`}</h4>
    <p>{`Invoke the `}<a parentName="p" {...{
        "href": "https://ipython.org/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`IPython interpreter`}</a>{` via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"parsertongue/python:latest"`}</span>{` ipython`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# run using the following command:`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# docker run -it "parsertongue/python:latest" ipython`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` numpy `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` np

x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
y `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{` y`}</code></pre></div>
    <p>{`The result:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h3 {...{
      "id": "multiplication",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#multiplication",
        "aria-label": "multiplication permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multiplication`}</h3>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`a`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`b`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`c`}</mi></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow><mo parentName="mrow">{`×`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`d`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`e`}</mi></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mi parentName="mstyle">{`f`}</mi></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`[`}</mo><mtable parentName="mrow" {...{
                      "rowspacing": "0.16em",
                      "columnalign": "center",
                      "columnspacing": "1em"
                    }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`a`}</mi><mo parentName="mrow">{`×`}</mo><mi parentName="mrow">{`d`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`b`}</mi><mo parentName="mrow">{`×`}</mo><mi parentName="mrow">{`e`}</mi></mrow></mstyle></mtd></mtr><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                            "scriptlevel": "0",
                            "displaystyle": "false"
                          }}><mrow parentName="mstyle"><mi parentName="mrow">{`c`}</mi><mo parentName="mrow">{`×`}</mo><mi parentName="mrow">{`f`}</mi></mrow></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                      "fence": "true"
                    }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\begin{bmatrix}
a \\\\
b \\\\
c \\\\
\\end{bmatrix}

\\times

\\begin{bmatrix}
d \\\\
e \\\\
f \\\\
\\end{bmatrix}

=

\\begin{bmatrix}
a \\times d \\\\
b \\times e \\\\
c \\times f \\\\
\\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`b`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span><span parentName="span" {...{
                "className": "mbin"
              }}>{`×`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2222em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`d`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`e`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.10764em"
                                  }
                                }}>{`f`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span><span parentName="span" {...{
                "className": "mrel"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.2778em"
                }
              }}></span></span><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.6em",
                  "verticalAlign": "-1.55em"
                }
              }}></span><span parentName="span" {...{
                "className": "minner"
              }}><span parentName="span" {...{
                  "className": "mopen"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎣`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M319 0 H403 V16 H319z M319 0 H403 V16 H319z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎡`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mord"
                }}><span parentName="span" {...{
                    "className": "mtable"
                  }}><span parentName="span" {...{
                      "className": "col-align-c"
                    }}><span parentName="span" {...{
                        "className": "vlist-t vlist-t2"
                      }}><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "2.05em"
                            }
                          }}><span parentName="span" {...{
                              "style": {
                                "top": "-4.21em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`a`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`×`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`d`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-3.01em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`b`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`×`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`e`}</span></span></span><span parentName="span" {...{
                              "style": {
                                "top": "-1.81em"
                              }
                            }}><span parentName="span" {...{
                                "className": "pstrut",
                                "style": {
                                  "height": "3em"
                                }
                              }}></span><span parentName="span" {...{
                                "className": "mord"
                              }}><span parentName="span" {...{
                                  "className": "mord mathnormal"
                                }}>{`c`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mbin"
                                }}>{`×`}</span><span parentName="span" {...{
                                  "className": "mspace",
                                  "style": {
                                    "marginRight": "0.2222em"
                                  }
                                }}></span><span parentName="span" {...{
                                  "className": "mord mathnormal",
                                  "style": {
                                    "marginRight": "0.10764em"
                                  }
                                }}>{`f`}</span></span></span></span><span parentName="span" {...{
                            "className": "vlist-s"
                          }}>{`​`}</span></span><span parentName="span" {...{
                          "className": "vlist-r"
                        }}><span parentName="span" {...{
                            "className": "vlist",
                            "style": {
                              "height": "1.55em"
                            }
                          }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                  "className": "mclose"
                }}><span parentName="span" {...{
                    "className": "delimsizing mult"
                  }}><span parentName="span" {...{
                      "className": "vlist-t vlist-t2"
                    }}><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "2.05em"
                          }
                        }}><span parentName="span" {...{
                            "style": {
                              "top": "-2.25em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎦`}</span></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-3.397em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "style": {
                                "height": "0.016em",
                                "width": "0.6667em"
                              }
                            }}><svg parentName="span" {...{
                                "xmlns": "http://www.w3.org/2000/svg",
                                "width": "0.6667em",
                                "height": "0.016em",
                                "style": {
                                  "width": "0.6667em"
                                },
                                "viewBox": "0 0 666.67 16",
                                "preserveAspectRatio": "xMinYMin"
                              }}><path parentName="svg" {...{
                                  "d": "M263 0 H347 V16 H263z M263 0 H347 V16 H263z"
                                }}></path></svg></span></span><span parentName="span" {...{
                            "style": {
                              "top": "-4.05em"
                            }
                          }}><span parentName="span" {...{
                              "className": "pstrut",
                              "style": {
                                "height": "3.155em"
                              }
                            }}></span><span parentName="span" {...{
                              "className": "delimsizinginner delim-size4"
                            }}><span parentName="span">{`⎤`}</span></span></span></span><span parentName="span" {...{
                          "className": "vlist-s"
                        }}>{`​`}</span></span><span parentName="span" {...{
                        "className": "vlist-r"
                      }}><span parentName="span" {...{
                          "className": "vlist",
                          "style": {
                            "height": "1.55em"
                          }
                        }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></span></div>
    <h4 {...{
      "id": "using-numpy-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#using-numpy-1",
        "aria-label": "using numpy 1 permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using NumPy`}</h4>
    <p>{`Invoke the `}<a parentName="p" {...{
        "href": "https://ipython.org/",
        "target": "_self",
        "rel": "nofollow"
      }}>{`IPython interpreter`}</a>{` via `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`docker`}</code>{`:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "command-line-prompt"
          }}><span parentName="span" {...{
              "data-user": "hahnpowell",
              "data-host": "gubuntu"
            }}></span></span><span parentName="code" {...{
            "className": "token function"
          }}>{`docker`}</span>{` run -it `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"parsertongue/python:latest"`}</span>{` ipython`}</code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`# run using the following command:`}</span>{`
`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# docker run -it "parsertongue/python:latest" ipython`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` numpy `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`as`}</span>{` np

x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
y `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`4`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

x `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span>{` y`}</code></pre></div>
    <p>{`The result:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}>{`np`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`array`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`3`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`8`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`12`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span></code></pre></div>
    <h1 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h1>
    <p>{`You now know some basic vector operations.  Before moving on to learning about `}<a parentName="p" {...{
        "href": "/tutorials/distance-and-similarity/"
      }}>{`comparing vectors`}</a>{`, let's practice ...`}</p>
    <h1 {...{
      "id": "practice",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#practice",
        "aria-label": "practice permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Practice`}</h1>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Given `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`a`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`[`}</mo><mtable parentName="mrow" {...{
                          "rowspacing": "0.16em",
                          "columnalign": "center center",
                          "columnspacing": "1em"
                        }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                "scriptlevel": "0",
                                "displaystyle": "false"
                              }}><mn parentName="mstyle">{`12`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                "scriptlevel": "0",
                                "displaystyle": "false"
                              }}><mn parentName="mstyle">{`30`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{a} = \\begin{bmatrix} 12 & 30 \\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`a`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1.2em",
                      "verticalAlign": "-0.35em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "minner"
                  }}><span parentName="span" {...{
                      "className": "mopen delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`[`}</span></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mtable"
                      }}><span parentName="span" {...{
                          "className": "col-align-c"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.85em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.01em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "3em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord"
                                    }}>{`12`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.35em"
                                }
                              }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                          "className": "arraycolsep",
                          "style": {
                            "width": "0.5em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "arraycolsep",
                          "style": {
                            "width": "0.5em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "col-align-c"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.85em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.01em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "3em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord"
                                    }}>{`30`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.35em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                      "className": "mclose delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`]`}</span></span></span></span></span></span></span>{`, what is its magnitude?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Given `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`a`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`[`}</mo><mtable parentName="mrow" {...{
                          "rowspacing": "0.16em",
                          "columnalign": "center center",
                          "columnspacing": "1em"
                        }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                "scriptlevel": "0",
                                "displaystyle": "false"
                              }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                "scriptlevel": "0",
                                "displaystyle": "false"
                              }}><mn parentName="mstyle">{`3`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{a} = \\begin{bmatrix} 2 & 3 \\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`a`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1.2em",
                      "verticalAlign": "-0.35em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "minner"
                  }}><span parentName="span" {...{
                      "className": "mopen delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`[`}</span></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mtable"
                      }}><span parentName="span" {...{
                          "className": "col-align-c"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.85em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.01em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "3em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord"
                                    }}>{`2`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.35em"
                                }
                              }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                          "className": "arraycolsep",
                          "style": {
                            "width": "0.5em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "arraycolsep",
                          "style": {
                            "width": "0.5em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "col-align-c"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.85em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.01em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "3em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord"
                                    }}>{`3`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.35em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                      "className": "mclose delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`]`}</span></span></span></span></span></span></span>{`, what vector has the same direction, but 3 times the magnitude of `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`a`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{a}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`a`}</span></span></span></span></span>{`?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Given `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`a`}</mi><mo parentName="mrow">{`=`}</mo><mrow parentName="mrow"><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`[`}</mo><mtable parentName="mrow" {...{
                          "rowspacing": "0.16em",
                          "columnalign": "center",
                          "columnspacing": "1em"
                        }}><mtr parentName="mtable"><mtd parentName="mtr"><mstyle parentName="mtd" {...{
                                "scriptlevel": "0",
                                "displaystyle": "false"
                              }}><mn parentName="mstyle">{`2`}</mn></mstyle></mtd></mtr></mtable><mo parentName="mrow" {...{
                          "fence": "true"
                        }}>{`]`}</mo></mrow></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{a} = \\begin{bmatrix} 2 \\end{bmatrix}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`a`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mrel"
                  }}>{`=`}</span><span parentName="span" {...{
                    "className": "mspace",
                    "style": {
                      "marginRight": "0.2778em"
                    }
                  }}></span></span><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "1.2em",
                      "verticalAlign": "-0.35em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "minner"
                  }}><span parentName="span" {...{
                      "className": "mopen delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`[`}</span></span><span parentName="span" {...{
                      "className": "mord"
                    }}><span parentName="span" {...{
                        "className": "mtable"
                      }}><span parentName="span" {...{
                          "className": "col-align-c"
                        }}><span parentName="span" {...{
                            "className": "vlist-t vlist-t2"
                          }}><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.85em"
                                }
                              }}><span parentName="span" {...{
                                  "style": {
                                    "top": "-3.01em"
                                  }
                                }}><span parentName="span" {...{
                                    "className": "pstrut",
                                    "style": {
                                      "height": "3em"
                                    }
                                  }}></span><span parentName="span" {...{
                                    "className": "mord"
                                  }}><span parentName="span" {...{
                                      "className": "mord"
                                    }}>{`2`}</span></span></span></span><span parentName="span" {...{
                                "className": "vlist-s"
                              }}>{`​`}</span></span><span parentName="span" {...{
                              "className": "vlist-r"
                            }}><span parentName="span" {...{
                                "className": "vlist",
                                "style": {
                                  "height": "0.35em"
                                }
                              }}><span parentName="span"></span></span></span></span></span></span></span><span parentName="span" {...{
                      "className": "mclose delimcenter",
                      "style": {
                        "top": "0em"
                      }
                    }}><span parentName="span" {...{
                        "className": "delimsizing size1"
                      }}>{`]`}</span></span></span></span></span></span></span>{`, what vector has the same magnitude, but the opposite direction to `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow" {...{
                        "mathvariant": "bold"
                      }}>{`a`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`\\mathbf{a}`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4444em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathbf"
                  }}>{`a`}</span></span></span></span></span>{`?`}</p>
      </li>
    </ul>
    <h1 {...{
      "id": "footnotes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#footnotes",
        "aria-label": "footnotes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Footnotes`}</h1>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "/tutorials/distance-and-similarity/#p-norm"
          }}>{`You'll learn why in the next lesson`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      